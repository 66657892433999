<template>
  <container>
    <div class="d-flex p-1 justify-content-end mb-2">
      <div class="d-flex mr-auto">
        <button
          @click="syncPrintNode"
          v-if="permissions.AuditLogSuperUserView"
          class="btn btn-primary"
        >
          Sync Print Node
        </button>
        <Loader v-if="syncing" class="my-auto ml-1" size="small" />
      </div>
      <div class="mr-2 d-inline-flex flex-column">
        <label><b>Show Hidden</b></label>
        <DxSwitch v-model="showHidden" :key="showHidden" />
      </div>
    </div>
    <div class="mb-3 p-1">
      <dx-grid-with-search
        gridName="distributions"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        :dataSource="pathReportMessages"
        :columns="columns"
        @initialized="initializeGrid"
        title="Distribution History"
        :columnChooser="true"
        :pageSize="12"
        :toolbar="toolbar"
      >
        <div slot="actions-cell" class="d-flex align-items-center" slot-scope="{ data }">
          <icon-button
            @click="getPathReportPDf(data)"
            class="mx-1 pointer text-primary p-0"
            v-tooltip.left="'View PDF'"
            icon="eye"
          />
          <icon-button
            v-if="data.data.messageStatus !== 'Pending'"
            v-tooltip.right="'Resubmit accession.'"
            class="mx-1 text-warning pointer p-0"
            @click="resubmitMessage(data)"
            icon="redo"
          />
          <icon-button
            v-if="data && data.data.messageStatus === 'Pending'"
            class="mx-1 text-danger pointer p-0"
            icon="ban"
            v-tooltip.left="'Cancel distribution'"
            @click="cancelMessage(data)"
          />
        </div>
      </dx-grid-with-search>
    </div>
    <modal :status="isViewerOpen" @close="isViewerOpen = false">
      <PDFViewer
        class="viewer"
        @print="handlePrint"
        @download="handlePdfDownload"
        :url="pdfUrl"
        @close="isViewerOpen = false"
        :externalDownload="true"
      />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "../components/common/DxGridWithSearch.vue";
import Modal from "../components/common/Modal.vue";
import PDFViewer from "../components/common/PDFViewer.vue";
import PathReportMessage from "../services/pathReportMessages";
import Reports from "../services/Reports";
import { mapGetters, mapState } from "vuex";
import {
  createLocalTzDateFilter,
  createLogItem,
  filterAccessionNumber,
  filterCellUTC,
  formatDatetimeCell
} from "../modules/helpers";
import { format } from "date-fns";
import printJS from "print-js";
import { AuditLogApi, PathReportMessagesApi } from "../services/index";
import DataSource from "devextreme/data/data_source";
import Container from "@/components/common/Container.vue";
import IconButton from "@/components/common/IconButton.vue";
import DxSwitch from "devextreme-vue/switch";
import { AuditLogItems } from "@/modules/enums";
import Loader from "@/components/common/Loader.vue";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: { DxGridWithSearch, Modal, PDFViewer, Container, IconButton, DxSwitch, Loader },
  data() {
    return {
      isViewerOpen: false,
      pdfUrl: "",
      startDate: "",
      syncing: false,
      grid: {},
      endDate: new Date(),
      remoteOperations: {
        paging: true,
        filtering: true,
        sorting: true
      },
      pathReportMessages: new DataSource({
        store: PathReportMessagesApi.searchStore,
        filter: this.showHidden ? null : ["hideInDistributionHistory", false]
      }),
      showHidden: false
    };
  },
  metaInfo: {
    title: "Distribution History",
    titleTemplate: "IntelliPath - %s"
  },

  methods: {
    initializeGrid({ component }) {
      this.grid = component;
    },
    async syncPrintNode() {
      const labId = this.$store.state.currentLab;
      try {
        this.syncing = true;
        await PathReportMessagesApi.syncPrintNodeJobs(labId);
        this.pathReportMessages.reload();
      } finally {
        this.syncing = false;
      }
    },
    getPathReportPDf({ data }) {
      const { pathReportId } = data;
      Reports.getPathReportById(pathReportId).then(pdf => {
        const blob = new Blob([pdf], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
        this.isViewerOpen = true;
      });
    },
    handlePdfDownload() {
      var a = document.createElement("a");
      a.href = this.pdfUrl;
      a.download = `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORT.pdf`;
      a.click();
    },
    handlePrint() {
      ///Make call for logging.
      printJS({
        printable: this.pdfUrl,
        type: "pdf",
        documentTitle: `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORT.pdf`
      });
    },
    async resubmitMessage({ data }) {
      const { pathReportMessageId } = data;
      const confirm = await window.confirm(
        `<p class="text-center m-auto">The accession will be resent by this distribution method in its current state not in the state it was sent in when it was sent previously.<br>
        Are you sure you want to resend?</p>`
      );
      if (!confirm) {
        return;
      }
      return PathReportMessage.resubmitMessage(pathReportMessageId).then(() => {
        window.notify("Success!");
        this.grid.refresh();
        const logItem = createLogItem(
          { caseNumber: data.caseNumber },
          AuditLogItems[data.messageType]
        );
        logItem.comments = `Resubmitted ${data.messageType} message with ID of ${pathReportMessageId}.`;
        AuditLogApi.insertLogMessage(logItem);
      });
    },
    cancelMessage({ data }) {
      const { pathReportMessageId } = data;
      return PathReportMessage.cancelMessage(pathReportMessageId).then(() => {
        window.notify("Success!");
        this.grid.refresh();
      });
    },
    exportToExcel() {
      let selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length) {
        this.grid.exportToExcel(true);
      } else {
        selectedRows = this.grid.getVisibleRows().map(e => e.data);
        this.grid.exportToExcel();
      }
      const logItem = createLogItem({}, 8, "Distribution History");
      logItem.comments =
        "Exported distributions to excel " + selectedRows.map(e => e.caseNumber).join(" \n ");
      AuditLogApi.insertLogMessage(logItem);
      return;
    },
    async handleResubmitSelected() {
      const selectedRows = this.grid.getSelectedRowsData();
      if (!selectedRows.length) {
        window.alert("Please select distributions to redistribute.");
        return;
      }
      try {
        await PathReportMessagesApi.bulkResubmit(selectedRows.map(e => e.pathReportMessageId));
        window.notify("Resubmitted selected distributions.");
        this.grid.selectRows([], false);
      } catch (error) {
        handleErrors(error);
      }
    }
  },
  computed: {
    ...mapState(["userTz"]),
    ...mapGetters(["permissions"]),
    columns() {
      return [
        {
          dataField: "messageType",
          dataType: "string"
        },
        {
          dataField: "messageStatus",
          dataType: "string"
        },
        {
          dataField: "caseNumber",
          dataType: "string",
          caption: "Case #",
          calculateFilterExpression: filterAccessionNumber(
            "caseNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },

        {
          dataField: "recipient",
          dataType: "string"
        },
        {
          dataField: "destination",
          dataType: "string"
        },
        {
          dataField: "sentOn",
          dataType: "datetime",
          sortOrder: "asc",
          sortIndex: 1,
          allowFiltering: true,
          selectedFilterOperation: "=",
          width: "160px",
          calculateCellValue(data) {
            if (data.sentOn) {
              return formatDatetimeCell(data.sentOn);
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("sentOn")
        },
        {
          dataField: "submittedBy",
          dataType: "string"
        },
        {
          dataField: "submittedOn",
          dataType: "datetime",
          sortIndex: 0,
          sortOrder: "desc",
          allowFiltering: true,
          width: "160px",
          calculateCellValue(data) {
            if (data.submittedOn) {
              return formatDatetimeCell(data.submittedOn);
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("submittedOn")
        },
        {
          dataField: "errorMessage",
          dataType: "string",
          caption: "Details"
        },
        {
          dataField: "failedOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.failedOn) {
              const formattedDate = formatDatetimeCell(data.failedOn);
              return format(formattedDate, "M/dd/yyyy");
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "failedOn");
          }
        },
        {
          dataField: "cancelledBy",
          dataType: "string"
        },
        {
          dataField: "cancelledOn",
          dataType: "datetime",
          calculateCellValue(data) {
            if (data.cancelledOn) {
              return formatDatetimeCell(data.cancelledOn);
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("cancelledOn")
        },
        {
          dataField: "faxStartedOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.faxStartedOn) {
              const formattedDate = formatDatetimeCell(data.faxStartedOn);
              return format(formattedDate, "M/dd/yyyy");
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "faxStartedOn");
          }
        },
        {
          dataField: "contact",
          dataType: "string"
        },
        {
          dataField: "copyName",
          dataType: "string"
        },
        {
          dataField: "caseStatus",
          dataType: "string"
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions-cell"
        }
      ];
    },
    toolbar() {
      return {
        visible: true,
        items: [
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                "data-testId": "toolbar-action-excel",
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-redo",
              onClick: this.handleResubmitSelected,
              hint: "Click to resubmit selected distributions",
              elementAttr: {
                class: "icon-color"
              },
              text: "Resubmit Selected Distributions"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    }
  },
  watch: {
    showHidden: {
      handler(nv) {
        if (nv) {
          this.grid.clearFilter("dataSource");
        } else {
          this.grid.filter(["hideInDistributionHistory", false]);
          this.grid.refresh();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .viewer {
  width: 42.5vw;
}
</style>
